import { Link } from "react-router-dom";

export default function Footer(){
    return(
        <>
     
        <div className="container-fluid bg-dark ml-2  position-relative text-white-50 footer overlay-top pt-5">
        <div className="container  py-5">
            <div className="row g-5">
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                    <Link to="index.html" className="d-inline-block mb-3">
                        <h2 className="text-white">ABITM Institute</h2>
                    </Link>
                    <p className="mb-0">ABITM Institute stands out as a premier Digital Marketing and Software Training institute in Kolkata.Renowned for its commitment to delivering top-notch education and ensuring a 100% placement assistance. </p>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                    <h5 className="text-white mb-4">Get In Touch</h5>
                    <p><i className="fa fa-map-marker-alt me-3"></i>26 No. Binodini Avenue, Hatiara Dhankol Road, Near Binodini Avenue park Kolkata - 700157</p>
                    <p><i className="fa fa-phone-alt me-3"></i>+91 6290 382 261</p>
                    <p><i className="fa fa-envelope me-3"></i>bhattacharjeegroup@gmail.com</p>
                    <div className="d-flex pt-2">
                        <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-youtube"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-instagram"></i></Link>
                        <Link className="btn btn-outline-light btn-social" to=""><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                    <h5 className="text-white mb-4">Popular Link</h5>
                    <Link className="btn btn-link" to="/about">About Us</Link>
                    <Link className="btn btn-link" to="/contact">Contact Us</Link>
                    <Link className="btn btn-link" to="/#">Privacy Policy</Link>
                    <Link className="btn btn-link" to="/#">Terms & Condition</Link>
                    <Link className="btn btn-link" to="/#">Career</Link>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                    <h5 className="text-white mb-4">Our Courses</h5>
                    <Link className="btn btn-link" to="/software_development">AI Machine Learning</Link>
                    <Link className="btn btn-link" to="/institution">Full Stack Development</Link>
                    <Link className="btn btn-link" to="/construction"> UI/UX Design</Link>
                    <Link className="btn btn-link" to="/hotel">Digital Marketing</Link>
                    
                </div>
            </div>
        </div>
        <div className="container wow fadeIn" data-wow-delay="0.1s">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <Link className="border-bottom" to="#">Robo Analytics</Link>, All Right Reserved.

                       
                        Designed By <Link className="border-bottom" to="#">Robo analytics</Link> Distributed By <Link className="border-bottom" to="#">Bhattacharjee Group</Link>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu">
                            <Link to="">Home</Link>
                            <Link to="">Cookies</Link>
                            <Link to="">Help</Link>
                            <Link to="">FAQs</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up"></i></a>
        </>
    )
}