import { useEffect } from "react";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default function Placement() {

    useEffect(() => {
        scrollToTop();
    }, []);

    const data = [
        {
            iconClass: 'fa-cogs',
            bgColor: 'bg-danger',
            title: 'Live Projects',
            description: 'If you have done live projects, getting the job is easier for you. We at Acesoftech Academy focus on projects. Our trainers help you to build projects during training sessions so that you get confidence and also you know how the real-life projects works. This helps in CV-Building as well as in interview cracking.'
        },
        {
            iconClass: 'fa-building',
            bgColor: 'bg-primary',
            title: 'Only in I.T. Companies',
            description: 'We place our candidates only in I.T. companies and not in BPO or other sectors unless we are confident that you will get good environment of working on projects as well as you will get good opportunities of learning.'
        },
        
        {
            iconClass: 'fa-book-open-reader',
            bgColor: 'bg-secondary',
            title: 'Brushing-up knowledge opportunities',
            description: 'What if you go for interviews to few companies and came to know that your certain part of the course is not good and needs brush-up? Even your course is finished, if you feel that your certain subject is not good enough to crack the interview, we again provide you the opportunities to come to centre and brush-up your knowledge.'
        },
        {
            iconClass: 'fa-location-dot',
            bgColor: 'bg-warning',
            title: 'We do not leave until placement',
            description: 'We do our best to prepare you before sending for interview. In spite of that of you are not selected. Remember we don’t leave you until we place you in any I.T. company.'
        },
        {
            iconClass: 'fa-user-graduate',
            bgColor: 'bg-primary',
            title: 'Interview questions and answers',
            description: 'We provide you mock-up interview questions and answers so that you can learn and be prepared for the real-interview.'
        },
        {
            iconClass: 'fa-id-card',
            bgColor: 'bg-warning',
            title: 'Help in C.V. Building',
            description: 'We help you also in C.V. Building so that you can present a good CV when you go for interview.'
        }
    ];


    return (
        <>
            <div className=" bg-primary hero-header ">
                <div className="jumbotron page-header jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "50px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">

                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>

                                <h1 className=" text-white mt-1  mb-3 animated slideInRight"> ABITE Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">Placements</h1>
                                {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Explore All Courses </div> */}

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid py-2 ">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-5 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="0.2s" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" src="../img/Institute/placements.png" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
                            <div className=" section-titles position-relative mb-4">
                                <h6 className="d-inline-block text-danger position-relative text-uppercase pb-2">Placement</h6>
                                <h1 className="">All Training institutes provide assurance that 100% placement support will be provided</h1>
                            </div>
                            <p>All Training institutes provide assurance that 100% placement support will be provided. While it’s a fact that not all students get placed in the I.T. Industry. The major reason is not providing/taking proper and industrial training which is required for current industry. Our main focus is to provide proper hands-on training. Rest of the things becomes easy, itself.
                                There are more or less 1700 I.T. companies in Kolkata where there is always requirements of freshers as well as experienced candidates. We regularly get request from the companies either through phone calls, emails or whatsApp for candidate’s placement. So, if you learn properly, there is nothing to worry about placement.</p>
                           

                        </div>

                        <div style={{ marginTop: "5%" }}>
                            <h1 style={{ marginBottom: "4%" }}>How ABITM  helps the candidates in placement:</h1>
                            {data.map((item, index) => (
                                <div key={index} className="d-flex mb-2">
                                    <div className={`btn-icon ${item.bgColor} mr-4`}>
                                        <i className={`fa fa-2x ${item.iconClass} text-white`}></i>
                                    </div>
                                    <div className="mt-n1">
                                        <h4>{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}