import { Link } from "react-router-dom";

import { useEffect } from "react";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function About(){
    useEffect(() => {
        scrollToTop();
    }, []);
    return(
        <>
     <div className=" bg-primary hero-header ">
                <div class="jumbotron_about jumbotron-fluid position-relative overlay-bottom" style={{ height: "655px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 pt-5">
                            {/* <h1 class="text-white mt-5 mb-4">Learn From Robo Analytics</h1>
            <h1 class="text-white display-1 mb-5">Education Courses</h1> */}
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">ABITM</div>
                                <h1 className=" text-white mt-1  mb-3 animated slideInRight">Latest Software 
                                 Courses</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">About ABITM Institute</h1>

                            </div>

                        </div>
                    </div>

                </div>
           </div>
        
        

           <div className="container-fluid py-5 ">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-5 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="0.2s" style={{ minHeight: "500px" }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100" src="../img/Institute/about1.jpg" style={{ objectFit: "cover",right:"50px" }} />
                            </div>
                        </div>
                        <div className="col-lg-7 about wow fadeIn" data-wow-delay="0.5s">
                            <div className=" section-titles position-relative mb-4">
                                <h6 className="d-inline-block text-danger position-relative text-uppercase pb-2">About Us</h6>
                                <h1 className="">Take The Next Step Toward Your Personal And Professional Goals With ABITM Institute</h1>
                            </div>
                            <p> <span>ABITM</span> is a leading Software and Digital Marketing training institute in India. Located in Indian Metro city Kolkata, the institute is known for its market-trend-oriented and quality training.

Our goal is to create the man power which are not only anywhere in India but the world.

Our aim is to make young generation aspiring IT guys job-oriented by providing them state-of-the art knowledge.

The institute is driven by   <span>Mr.Abhishek Bhattacharjee</span> who has been in IT sector for more than 20 years. We have our own IT Company called <span>Robo Analytics</span>. which have international clients.

We assure you that we will try our best to make you a professional IT person who can work in any company as plug and play.</p>
                            <div className="row pt-3 mx-0">
                                <div className="col-3 px-0">
                                    <div className="bg-success text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">123</h1>
                                        <h6 className="text-uppercase text-white">Available<span className="d-block">Subjects</span></h6>
                                    </div>
                                </div>
                                <div className="col-3 px-0">
                                    <div className="bg-primary text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">1234</h1>
                                        <h6 className="text-uppercase text-white">Online<span className="d-block">Courses</span></h6>
                                    </div>
                                </div>
                                <div className="col-3 px-0">
                                    <div className="bg-secondary text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">123</h1>
                                        <h6 className="text-uppercase text-white">Skilled<span className="d-block">Instructors</span></h6>
                                    </div>
                                </div>
                                <div className="col-3 px-0">
                                    <div className="bg-warning text-center p-4">
                                        <h1 className="text-white" data-toggle="counter-up">1234</h1>
                                        <h6 className="text-uppercase text-white">Happy<span className="d-block">Students</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
    )
}