import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

 


export default function Digital_marketing_courses() {
    useEffect(() => {
        scrollToTop();
    }, []);
    const courseData = [


        { id: 6,
            image: "img/Institute/digital marketing.png",
            title: "DIGITAL MARKETING COURSES",
            description: "SEO, Email Marketing, Google Adwords, Facebook Ad's",
            price: "18000",
            duration:'5-6 months',
            insideHeading: "DIGITAL MARKETING ",
            videoSrc: "img/Institute/digital marketing.png"
        },
       
        { id: 24,
            image: "img/Institute/seo.jpg",
            title: "SEO",
            description: "Discover effective SEO strategies and techniques in this practical course. ",
            price: "8000",
            duration:'1 months',
            insideHeading: "SEO",
            videoSrc: "img/Institute/seo.jpg"
        },

    ];

  

    return (
        <>
            <div className="bg-primary hero-header">
                <div className="jumbotron page-header jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "20px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <h1 className="text-white mt-1 mb-3 animated slideInRight">Learn From ABITM Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">Digital Marketing Courses</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-area">
                {courseData.map((card, index) => (
                    <div key={index} className="card">
                        <div className="flip-card">
                            <div className="flip-card__container">
                                <div className="card-front">
                                    <div className='card-front__tp'>
                                        <img src={card.image} alt={card.insideHeading} className="card-front__image" />
                                    </div>
                                    <div className="card-front__bt">
                                        <p className='card-front__text-view'>{card.title}</p>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <img className="" src={card.videoSrc} alt={`${card.title} video`} />
                                </div>
                            </div>
                        </div>
                        <div className="inside-page">
                            <div className="inside-page__container">
                                <h3 className='inside-page__heading'>{card.insideHeading}</h3>
                                <p className="inside-page__text">
                                    {card.description}
                                    <br />
                                    <strong>Price:</strong> ₹{card.price}
                                    <br />
                                    <strong>Duration:</strong> {card.duration}
                                </p>
                                <Link to={`/course_details/${card.id}`} className="inside-page__btn inside-page__btn--camping">Learn More</Link>

                            </div>


                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}


