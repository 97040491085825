import { Link } from "react-router-dom";

// Define your menuItems array here or import it if it's in a separate file
const menuItems = [
    {
        id: 'ai-machine-learning',
        title: 'AI Machine Learning',
        links: [
            { label: 'AI Machine Learning' },
            { id: 'course_1', to: "/course_details/5", label: 'Artificial Intelligence' },
            { id: "course_2", to: "/course_details/13", label: 'Machine Learning' },
            { id: 'course_3', to: "/course_details/12", label: 'Data Analytics' }
        ]
    },
    {
        id: 'front-end-frameworks',
        title: 'Front-End/Frameworks',
        links: [
            { label: 'Front-End/Frameworks' },
            { id: 'course_4', to: "/course_details/16", label: 'React Js' },
            { id: 'course_5', to: "/course_details/20", label: 'Angular' },
            { id: 'course_6', to: "/course_details/18", label: 'Next Js' }
        ]
    },

    {
        id: 'full-stack-courses',
        title: 'Full Stack Courses',
        links: [
            { label: 'Full Stack Courses' },
            { id: 'course_7', to: "/course_details/3", label: 'MERN Stack Full Stack' },
            { id: 'course_8', to: "/course_details/8", label: 'Python Full Stack' },
            { id: 'course_9', to: "/course_details/15", label: 'PHP MYSQL Full Stack' }
        ]
    },
    {
        id: 'python',
        title: 'Python',
        links: [
            { label: 'Python' },
            { id: 'course_10', to: "/course_details/9", label: 'Python Programming' },
            { id: 'course_11', to: "/course_details/12", label: 'Python Frameworks' },
            { id: 'course_12', to: "/course_details/8", label: 'Python' }
        ]
    },

    {
        id: 'ui-ux-design',
        title: 'UI/UX Design',
        links: [
            { label: 'UI/UX Design' },
            { id: 'course_13', to: "/course_details/1", label: 'Web Design' },
            { id: 'course_14', to: "/course_details/1", label: 'Figma' },
            { id: 'course_15', to: "/course_details/1", label: '6 Months Web Design Course' }
        ]
    },

    {
        id: 'digital-marketing',
        title: 'Digital Marketing',
        links: [
            { label: 'Digital Marketing' },
            { id: 'course_16', to: "/course_details/6", label: '6 Months Diploma' },
            { id: 'course_16', to: "/course_details/6", label: '4 Months Certificate' },
            { id: 'course_16', to: "/course_details/6", label: 'For E-Commerce' }
        ]
    },
    {
        id: 'web-development',
        title: 'Web Development',
        links: [
            { label: 'Web Development' },
            { id: 'course_17', to: "/course_details/7", label: 'HTML' },
            { id: 'course_17', to: "/course_details/7", label: 'CSS' },
            { id: 'course_17', to: "/course_details/7", label: 'JavaScript' },
            { id: 'course_17', to: "/course_details/7", label: 'Jquery' },
            { id: 'course_17', to: "/course_details/7", label: 'Node Js' }
        ]
    },
    {
        id: 'basics-computer-course',
        title: 'Basics Computer Course',
        links: [
            { label: 'Basics Computer Course' },
            { id: 'course_18', to: "/course_details/21", label: 'C++' },
            { id: 'course_18', to: "/course_details/22", label: 'Wordpress' },
            { id: 'course_18', to: "/course_details/22", label: 'MS Excel' },
            { id: 'course_18', to: "/course_details/22", label: 'Paint' }
        ]
    },
    {
        id: 'prompt',
        title: 'Prompt',
        links: [
            { label: 'Prompt' },
            { id: 'course_19', to: "/course_details/23", label: 'Prompt' }
        ]
    },
    {
        id: 'seo',
        title: 'SEO',
        links: [
            { label: 'SEO' },
            { id: 'course_', to: "/course_details/24", label: 'SEO' }
        ]
    },
];

export default function Header() {
    return (
        <>
            <div className="top_header_menu container-fluid">
                <div className="row py-2 px-lg-5">
                    <div className="col-lg-6 text-lg-left mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center text-white" style={{ fontSize: "15px" }}>
                            <small><i className="fa fa-phone-alt"></i>+91 6290382261</small>
                            <small><i className="fa fa-envelope mr-2" style={{ margin: "0px 10px" }}></i>bhattacharjeegroup@gmail.com</small>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end text-lg-right">
                        <div className="d-inline-flex align-items-center">
                            <Link className="text-white px-2" to=""><i className="fab fa-facebook-f"></i></Link>
                            <Link className="text-white px-2" to=""><i className="fab fa-twitter"></i></Link>
                            <Link className="text-white px-2" to=""><i className="fab fa-linkedin-in"></i></Link>
                            <Link className="text-white px-2" to=""><i className="fab fa-instagram"></i></Link>
                            <a className="text-white px-2" href="https://www.youtube.com/channel/UCXQa4S8DQqzPVYt4YgJRdJw"target="blank"><i className="fab fa-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sticky-top bg-primary">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-dark p-0">
                        <Link to="/" className="navbar-brand">
                            <img className="logo" style={{ height: "60px" }} src="../img/logo11.png" alt="" />
                            {/* <h1 className="text-light">ABITM</h1> */}
                        </Link>
                        <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto">
                                <Link to="/" className="nav-item nav-link active">Home</Link>
                                <div className="nav-item dropdown position-static">
                                    <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">All Courses</Link>
                                    <div className="dropdown-menu bg-light w-100 mt-2 d-flex flex-wrap position-absolute top-100 start-0" style={{ right: "0px", left: "0px", zIndex: 1000 }}>
                                        {menuItems.map((item) => (
                                            <div key={item.id} className="mr-3 flex-fill">
                                                <ul className="list-unstyled ">
                                                    <li><Link to={item.links[0].id} className="dropdown-item"><div className="dropdown_header">{item.title}</div></Link></li>
                                                    {item.links.slice(1).map((link, id) => (
                                                        <li key={link.id}><Link to={link.to} className="dropdown-item">{link.label}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Link to="/about" className="nav-item nav-link">About Us</Link>
                                <Link to="/placement" className="nav-item nav-link">Placement</Link>
                                {/* <Link to="/project" className="nav-item nav-link">Projects</Link> */}
                                <Link to="/contact" className="nav-item nav-link">Contact</Link>
                                {/* <butaton type="button" className="btn text-white p-0 d-none d-lg-block" data-bs-toggle="modal"
                                    data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton> */}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}




// import { Link } from "react-router-dom";

// const links = [
//     { id: "home", to: "/", text: "Home" },

//     { id: "all_courses", to: "#", text: "All Courses", dropdown: true, categories: [
//         { id: "ai_ml", header: "AI Machine Learning", items: [
//             { id: "ai_ml_1", to: "/course_details/1", text: "Artificial Intelligence" },
//             { id: "ai_ml_2", to: "/course_details/2", text: "Machine Learning" },
//             { id: "ai_ml_3", to: "/course_details/3", text: "Data Analytics" },
//         ]},

//         { id: "frontend_frameworks", header: "Front-End/Frameworks", items: [
//             { id: "frontend_1", to: "/course_details/4", text: "React Js" },
//             { id: "frontend_2", to: "/course_details/5", text: "Angular" },
//             { id: "frontend_3", to: "/course_details/6", text: "Next Js" },
//         ]},

//         { id: "full_stack", header: "Full Stack Courses", items: [
//             { id: "full_stack_1", to: "/course_details/7", text: "MERN Stack Full Stack" },
//             { id: "full_stack_2", to: "/course_details/8", text: "Python Full Stack" },
//             { id: "full_stack_3", to: "/course_details/9", text: "PHP MYSQL Full Stack" },
//         ]},
//         { id: "python", header: "Python", items: [
//             { id: "python_1", to: "/course_details/10", text: "Python Programming" },
//             { id: "python_2", to: "/course_details/11", text: "Python frameworks" },
//             { id: "python_3", to: "/course_details/12", text: "django" },
//         ]},
//         { id: "ui_ux", header: "UI/UX design", items: [
//             { id: "ui_ux_1", to: "/course_details/13", text: "Photoshop" },
//             { id: "ui_ux_2", to: "/course_details/14", text: "Figma" },
//             { id: "ui_ux_3", to: "/course_details/15", text: "6 Months Web Design Course" },
//         ]},
//         { id: "basics", header: "Basics Computer Course", items: [
//             { id: "basics_1", to: "/course_details/16", text: "C++" },
//             { id: "basics_2", to: "/course_details/17", text: "Wordpress" },
//             { id: "basics_3", to: "/course_details/18", text: "MS.Excel" },
//             { id: "basics_4", to: "/course_details/19", text: "Paint" },
//         ]},
//         { id: "digital_marketing", header: "Digital Marketing", items: [
//             { id: "digital_1", to: "/course_details/20", text: "6 Months Diploma" },
//             { id: "digital_2", to: "/course_details/21", text: "4 Months Certificate" },
//             { id: "digital_3", to: "/course_details/22", text: "For E-Commerce" },
//         ]},
//         { id: "web_development", header: "Web Development", items: [
//             { id: "web_dev_1", to: "/course_details/23", text: "HTML" },
//             { id: "web_dev_2", to: "/course_details/24", text: "CSS" },
//             { id: "web_dev_3", to: "/course_details/25", text: "JavaScript" },
//             { id: "web_dev_4", to: "/course_details/26", text: "Jquery" },
//             { id: "web_dev_5", to: "/course_details/27", text: "Node Js" },
//         ]}
//     ]},
//     { id: "about_us", to: "/about", text: "About Us" },
//     { id: "placement", to: "/placement", text: "Placement" },
//     { id: "contact", to: "/contact", text: "Contact" },
// ];

// export default function Header() {
//     return (
//         <>
//             <div className="top_header_menu container-fluid">
//                 <div className="row py-2 px-lg-5">
//                     <div className="col-lg-6 text-lg-left mb-2 mb-lg-0">
//                         <div className="d-inline-flex align-items-center text-white" style={{ fontSize: "15px" }}>
//                             <small><i className="fa fa-phone-alt"></i> +91 6290382261</small>
//                             <small><i className="fa fa-envelope mr-2" style={{ margin: "0px 10px" }}></i>bhattacharjeegroup@gmail.com</small>
//                         </div>
//                     </div>
//                     <div className="col-lg-6 text-end text-lg-right">
//                         <div className="d-inline-flex align-items-center">
//                             {["facebook-f", "twitter", "linkedin-in", "instagram", "youtube"].map((icon, index) => (
//                                 <Link key={index} className="text-white px-2" to="">
//                                     <i className={`fab fa-${icon}`}></i>
//                                 </Link>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="container-fluid sticky-top bg-primary">
//                 <div className="container">
//                     <nav className="navbar navbar-expand-lg navbar-dark p-0">
//                         <Link to="/" className="navbar-brand">
//                             <img className="logo" style={{ height: "60px" }} src="img/logo11.png" alt="" />
//                             {/* <h1 className="text-light">ABITM</h1> */}
//                         </Link>
//                         <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
//                             data-bs-target="#navbarCollapse">
//                             <span className="navbar-toggler-icon"></span>
//                         </button>
//                         <div className="collapse navbar-collapse" id="navbarCollapse">
//                             <div className="navbar-nav ms-auto">
//                                 {links.filter(link => !link.dropdown).map(link => (
//                                     <Link key={link.id} id={link.id} to={link.to} className="nav-item nav-link">
//                                         {link.text}
//                                     </Link>
//                                 ))}
//                                 {links.filter(link => link.dropdown).map(link => (
//                                     <div key={link.id} className="nav-item dropdown position-static">
//                                         <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">{link.text}</Link>
//                                         <div className="dropdown-menu bg-light w-100 mt-2 d-flex flex-wrap position-absolute top-100 start-0" style={{ right: "0px", left: "0px", zIndex: 1000 }}>
//                                             {link.categories.map(category => (
//                                                 <div key={category.id} className="mr-3 flex-fill">
//                                                     <ul className="list-unstyled">
//                                                         <li><div className="dropdown_header">{category.header}</div></li>
//                                                         {category.items.map(item => (
//                                                             <li key={item.id}><Link id={item.id} to={item.to} className="dropdown-item">{item.text}</Link></li>
//                                                         ))}
//                                                     </ul>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     </nav>
//                 </div>
//             </div>
//         </>
//     )
// }

