import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';


const scrollToTop = () => {
    window.scrollTo(0, 0);
};

   
export default function Ai_courses() {
    useEffect(() => {
        scrollToTop();
    }, []);

    const courseData = [

        { id: 5,
            image: "img/Institute/ai.png",
            title: "AI COURSES",
            description: "An introduction to artificial intelligence principles and applications.",
            price: "18000",
            duration:'5-6 months',
            insideHeading: "AI COURSES",
            videoSrc: "img/Institute/ai.png"
        },

       
      
        { id: 11,
            image: "img/Institute/master of data analytics.jpg",
            title: "MASTER OF DATA ANALYTICS",
            description: "Advanced skills in data analysis, statistical modeling, and data-driven decision-making. ",
            price: "15000",
            duration:'3 months',
            insideHeading: "MASTER OF DATA ANALYTICS",
            videoSrc: "img/Institute/master of data analytics.jpg"
        },
        {  id: 12,
            image: "img/Institute/data analytics.jpg",
            title: "DATA ANALYTICS COURSES",
            description: "Comprehensive introduction to data science using R programming. ",
            price: "15000",
            duration:'3 months',
            insideHeading: "DATA ANALYTICS COURSES",
            videoSrc: "img/Institute/data analytics.jpg"
        },
        { id: 13,
            image: "img/Institute/ml.jpg",
            title: "MACHINE LEARNING",
            description: "Learn the basics of machine learning, from data processing to model evaluation. ",
            price: "18000",
            duration:'3 months',
            insideHeading: "MACHINE LEARNING",
            videoSrc: "img/Institute/ml.jpg"
        },

        { id: 14,
            image: "img/Institute/ml ai.jpg",
            title: "ML/AI COURSES",
            description: "Explore essential ML/AI concepts, algorithms, and applications. ",
            price: "20000",
            duration:'3 months',
            insideHeading: "ML/AI COURSES",
            videoSrc: "img/Institute/ml ai.jpg"
        },
   
        {id: 23,
            image: "img/Institute/prompt.jpg",
            title: "PROMPT ENGINEERING ",
            description: "Learn the principles and practices of prompt engineering for effective natural language processing tasks. ",
            price: "10000",
            duration:'1 months',
            insideHeading: "PROMPT ENGINEERING",
            videoSrc: "img/Institute/prompt.jpg"
        },
       

    ];



    return (
        <>
            <div className="bg-primary hero-header">
                <div className="jumbotron page-header jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "20px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <h1 className="text-white mt-1 mb-3 animated slideInRight">Learn From ABITM Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">Ai Machine Learning Courses</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-area">
                {courseData.map((card, index) => (
                    <div key={index} className="card">
                        <div className="flip-card">
                            <div className="flip-card__container">
                                <div className="card-front">
                                    <div className='card-front__tp'>
                                        <img src={card.image} alt={card.insideHeading} className="card-front__image" />
                                    </div>
                                    <div className="card-front__bt">
                                        <p className='card-front__text-view'>{card.title}</p>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <img className="" src={card.videoSrc} alt={`${card.title} video`} />
                                </div>
                            </div>
                        </div>
                        <div className="inside-page">
                            <div className="inside-page__container">
                                <h3 className='inside-page__heading'>{card.insideHeading}</h3>
                                <p className="inside-page__text">
                                    {card.description}
                                    <br />
                                    <strong>Price:</strong> ₹{card.price}
                                    <br />
                                    <strong>Duration:</strong> {card.duration}
                                </p>
                                <Link to={`/course_details/${card.id}`} className="inside-page__btn inside-page__btn--camping">Learn More</Link>

                            </div>


                        </div>
                    </div>
                ))}
            </div>

        </>
    );
}


