import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const scrollToTop = () => {
    window.scrollTo(0, 0);
};



   const courseData = [
        {
            id: 1,
            image: "img/Institute/ui.png",
            title: "WEB DESIGN",
            description: 'HTML, CSS, Bootstrap, Mobile & Browser Compatibility.',
            price: "15000",
            duration: '3 months',
            insideHeading: "WEB DESIGN",
            videoSrc: "img/Institute/ui.png"
        },
        {
            id: 2,
            image: "img/Institute/full stack.jpg",
            title: "FULL STACK COURSES",
            description: 'Build expertise in full-stack development from frontend to backend.',
            price: "15000",
            duration: '4 months',
            insideHeading: "FULL STACK COURSES",
            videoSrc: "img/Institute/full stack.jpg"
        },
        {
            id: 3,
            image: "img/Institute/mern.jpg",
            title: "MERN STACK COURSES",
            description: "MongoDB, Express, ReactJS, Node.js.",
            price: "18000",
            duration: '5-6 months',
            insideHeading: "MERN STACK COURSES",
            videoSrc: "img/Institute/mern.jpg",
            link: "/course_details"
        },
        {
            id: 4,
            image: "img/Institute/mean.jpg",
            title: "MEAN STACK COURSES",
            description: "MongoDB, Express, AngularJS , Node.js.",
            price: "18000",
            duration: '5-6 months',
            insideHeading: "MEAN STACK COURSES",
            videoSrc: "img/Institute/mean.jpg"
        },
        {
            id: 5,
            image: "img/Institute/ai.png",
            title: "AI COURSES",
            description: "An introduction to artificial intelligence principles and applications.",
            price: "18000",
            duration: '5-6 months',
            insideHeading: "AI COURSES",
            videoSrc: "img/Institute/ai.png"
        },
        {
            id: 6,
            image: "img/Institute/digital marketing.png",
            title: "DIGITAL MARKETING COURSES",
            description: "SEO, Email Marketing, Google Adwords, Facebook Ad's",
            price: "18000",
            duration: '5-6 months',
            insideHeading: "DIGITAL MARKETING ",
            videoSrc: "img/Institute/digital marketing.png"
        },
        {
            id: 7,
            image: "img/Institute/web dev.png",
            title: "WEB DEVELOPMENT COURSES",
            description: "Discover the essentials of web development: from HTML and CSS to JavaScript and beyond.",
            price: "18000",
            duration: '5-6 months',
            insideHeading: "WEB DEVELOPMENT ",
            videoSrc: "img/Institute/web dev.png"
        },
        {
            id: 8,
            image: "img/Institute/python full stack.jpg",
            title: "PYTHON FULL STACK",
            description: "Python & Django Framework Course for candidates willing to embark on full-stack development",
            price: "18000",
            duration: '5-6 months',
            insideHeading: "PYTHON FULL STACK",
            videoSrc: "img/Institute/python full stack.jpg"
        },
        {
            id: 9,
            image: "img/Institute/python programing.jpg",
            title: "PYTHON PROGRAMING ",
            description: "Master Python programming fundamentals for versatile application development.",
            price: "10000",
            duration: '1 months',
            insideHeading: "PYTHON PROGRAMING",
            videoSrc: "img/Institute/python programing.jpg"
        },
        {
            id: 10,
            image: "img/Institute/python.jpg",
            title: "PYTHON COURSES",
            description: "Python & Django Framework Course ",
            price: "8000",
            duration: '1 months',
            insideHeading: "PYTHON COURSES",
            videoSrc: "img/Institute/python.jpg"
        },
        {
            id: 11,
            image: "img/Institute/master of data analytics.jpg",
            title: "MASTER OF DATA ANALYTICS",
            description: "Advanced skills in data analysis, statistical modeling, and data-driven decision-making. ",
            price: "15000",
            duration: '3 months',
            insideHeading: "MASTER OF DATA ANALYTICS",
            videoSrc: "img/Institute/master of data analytics.jpg"
        },
        {
            id: 12,
            image: "img/Institute/data analytics.jpg",
            title: "DATA ANALYTICS COURSES",
            description: "Comprehensive introduction to data science using R programming. ",
            price: "15000",
            duration: '3 months',
            insideHeading: "DATA ANALYTICS COURSES",
            videoSrc: "img/Institute/data analytics.jpg"
        },
        {
            id: 13,
            image: "img/Institute/ml.jpg",
            title: "MACHINE LEARNING",
            description: "Learn the basics of machine learning, from data processing to model evaluation. ",
            price: "18000",
            duration: '3 months',
            insideHeading: "MACHINE LEARNING",
            videoSrc: "img/Institute/ml.jpg"
        },
        {
            id: 14,
            image: "img/Institute/ml ai.jpg",
            title: "ML/AI COURSES",
            description: "Explore essential ML/AI concepts, algorithms, and applications. ",
            price: "20000",
            duration: '3 months',
            insideHeading: "ML/AI COURSES",
            videoSrc: "img/Institute/ml ai.jpg"
        },
        {
            id: 15,
            image: "img/Institute/php.jpg",
            title: "PHP FULL STACK",
            description: "Master PHP and full-stack web development, from frontend to backend.",
            price: "18000",
            duration: '3 months',
            insideHeading: "PHP FULL STACK",
            videoSrc: "img/Institute/php.jpg"
        },
        {
            id: 16,
            image: "img/Institute/react js.jpg",
            title: "REACT JS",
            description: "HTML5, CSS3, ES6, Component, Redux, Node API. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "REACT JS",
            videoSrc: "img/Institute/react js.jpg"
        },
        {
            id: 17,
            image: "img/Institute/node js.jpg",
            title: "NODE JS",
            description: "Development Environment, REPL, NPM, DB connectivity, Express. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "NODE JS",
            videoSrc: "img/Institute/node js.jpg"
        },
        {
            id: 18,
            image: "img/Institute/frontend.jpg",
            title: "FRONTEND COURSES",
            description: "HTML5, CSS3, ES6,React js,Angular js,Next js. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "FRONTEND COURSES",
            videoSrc: "img/Institute/frontend.jpg"
        },
        {
            id: 19,
            image: "img/Institute/next js.jpg",
            title: "NEXT JS",
            description: "Learn to build fast, modern web apps with Next.js. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "NEXT JS",
            videoSrc: "img/Institute/next js.jpg"
        },
        {
            id: 20,
            image: "img/Institute/angular.jpg",
            title: "ANGULAR",
            description: "HTML5, CSS3, SCSS, TypeScript , ES6, Routing. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "ANGULAR",
            videoSrc: "img/Institute/angular.jpg"
        },
        {
            id: 21,
            image: "img/Institute/basic.jpg",
            title: "C, C++, CCC ",
            description: "C programming, Basic Network and Basic Electrical & Electronics. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "C, C++ ,CCC",
            videoSrc: "img/Institute/basic.jpg"
        },
        {
            id: 22,
            image: "img/Institute/basicss.jpg",
            title: "BASICS COMPUTER COURSES",
            description: "Learn the fundamentals of computer basics and essential skills. ",
            price: "5000",
            duration: '1 months',
            insideHeading: "BASICS COMPUTER COURSES",
            videoSrc: "img/Institute/basicss.jpg"
        },
        {
            id: 23,
            image: "img/Institute/prompt.jpg",
            title: "PROMPT ENGINEERING ",
            description: "Learn the principles and practices of prompt engineering for effective natural language processing tasks. ",
            price: "10000",
            duration: '1 months',
            insideHeading: "PROMPT ENGINEERING",
            videoSrc: "img/Institute/prompt.jpg"
        },
        {
            id: 24,
            image: "img/Institute/seo.jpg",
            title: "SEO",
            description: "Discover effective SEO strategies and techniques in this practical course. ",
            price: "8000",
            duration: '1 months',
            insideHeading: "SEO",
            videoSrc: "img/Institute/seo.jpg"
        }
    ];
export default function All_courses() {

    useEffect(() => {
        scrollToTop();
    }, []);
    

    

    return (
        <>
        <div className='course_container'> 
            <div className="bg-primary hero-header">
                <div className="jumbotron page-header jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "20px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <h1 className="text-white mt-1 mb-3 animated slideInRight">Learn From ABITM Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">All Courses</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-area">
                
                {courseData.map((card, index) => (
                    <div key={index} className='card_container'>
                    <div  className="card">
                        <div className="flip-card">
                            <div className="flip-card__container">
                                <div className="card-front">
                                    <div className='card-front__tp'>
                                        <img src={card.image} alt={card.insideHeading} className="card-front__image" />
                                    </div>
                                    <div className="card-front__bt">
                                        <p className='card-front__text-view'>{card.title}</p>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <img className="" src={card.videoSrc} alt={`${card.title} video`} />
                                </div>
                            </div>
                        </div>
                        <div className="inside-page">
                            <div className="inside-page__container">
                                <h6 className='inside-page__heading'>{card.insideHeading}</h6>
                                <p className="inside-page__text">
                                    {card.description}
                                    <br />
                                    <strong>Price:</strong> ₹{card.price}
                                    <br />
                                    <strong>Duration:</strong> {card.duration}
                                </p>
                                <Link to={`/course_details/${card.id}`} className="inside-page__btn inside-page__btn--camping">Learn More</Link>

                            </div>


                        </div>
                    </div>
                    </div>
                ))}
            </div>
        </div>
        </>
    );
}


{/* <div className='course_card_container'>
<div className="card">
      <img
        className="card__background"
        src="https://i.imgur.com/QYWAcXk.jpeg"
        alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
        width="1920"
        height="2193"
      />
      <div className="card__content flow">
        <div className="card__content--container flow">
          <h2 className="card__title">Colombia</h2>
          <p className="card__description">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum in
            labore laudantium deserunt fugiat numquam.
          </p>
        </div>
        <button className="card__button">Read more</button>
      </div>
    </div>
    </div> */}