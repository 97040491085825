import { useState } from "react"
import { useEffect } from "react";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default function Contact(){
    useEffect(() => {
        scrollToTop();
    }, []);
    const [value,setValue]=useState({
        name:"",
        email:"",
        subject:""
    })
    const{name,email,subject}=value
    const handleChange=e=>{
        setValue({...value,[e.target.name]:e.target.value})
    }
    const onSubmit=e=>{
        e.preventDefault()
        console.log("submit",value)
        setValue({
            name:"",
            email:"",
            subject:""
        })

    }
    return(
        <>
        <div className=" bg-primary hero-header ">
                <div className="jumbotron_about jumbotron-fluid position-relative overlay-bottom" style={{ height: "655px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 pt-5">
                            {/* <h1 className="text-white mt-5 mb-4">Learn From Robo Analytics</h1>
            <h1 className="text-white display-1 mb-5">Education Courses</h1> */}
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">ABITM</div>
                                <h1 className=" text-white mt-1  mb-3 animated slideInRight">ABITM Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">If You Have Any Query, Please Contact Us</h1>

                            </div>

                        </div>
                    </div>

                </div>
           </div>

        {/* <div className="modal fade" id="searchModal" tabindex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(20, 24, 62, 0.7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn btn-square bg-white btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-light p-3"
                            placeholder="Type search keyword"/>
                        <button className="btn btn-light px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
        </div> */}

<div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title-hotel text-center text-primary text-uppercase">Contact Us</h6>
                        <h1 className="mb-5"><span className="text-primary text-uppercase">Contact</span> For Any Query</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-4">
                                    <h6 className="section-title-hotel  text-start text-primary text-uppercase">Email Id</h6>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>bhattacharjeegroup@gmail.com
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <h6 className="section-title-hotel  text-start text-primary text-uppercase">Contact No</h6>
                                    <p><i className="fa fa-phone-alt text-primary me-2"></i>+91 6290 382 261</p>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                            <iframe className="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/d/embed?mid=1zFQgdjVjsLER9xwKzsMUUONb5eYEeJ0&ehbc=2E312F"
                                tabindex="0"></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: "150px" }}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}