
import { Link } from "react-router-dom";
import React, { useEffect } from 'react';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaFileAlt, FaUserCog, FaUserGraduate } from "react-icons/fa";
import { FaUsersGear } from "react-icons/fa6";



const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function Institution() {
    useEffect(() => {
        scrollToTop();
    }, []);

    const options = {
        autoplay: false,
        smartSpeed: 1500,
        loop: false,
        dots: false,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            }
        }
    };

    const team_carousel = ({
        autoplay: true,
        smartSpeed: 1000,
        margin: 30,
        dots: true,
        center: true,
        loop: true,
        nav: false,
        navText: [
            '<i class="bi bi-arrow-left"></i>',
            '<i class="bi bi-arrow-right"></i>'
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        }
    });

    const testimonial_carousel = ({
        autoplay: true,
        smartSpeed: 1500,
        items: 1,
        dots: false,
        loop: true,



    });

    const courses = [
        {
            img: "img/Institute/courses-1.jpg",
            title: "AI Machine Learning",
            instructor: "6 courses",
            rating: 4,
            reviews: 'weeks',
            link: "/ai_courses"
        },
        {
            img: "img/Institute/courses-2.jpg",
            title: "Full Stack Courses",
            instructor: "4 courses",
            rating: 12,
            reviews: "weeks",
            link: "/full_stack_courses"
        },
        {
            img: "img/Institute/courses-3.jpg",
            title: "UI/UX design",
            instructor: "8 courses",
            rating: 10,
            reviews: 'weeks',
            link: "/ui_ux_design"
        },
        {
            img: "img/Institute/courses-4.jpg",
            title: "Digital Marketing",
            instructor: "2 courses",
            rating: 5,
            reviews: 'weeks',
            link: "/digital_marketing"
        },

    ];

    const testimonialData = [
        {
            text: "I enrolled at Data Science course in ABITE Institute. I am highly recommended that it is much better than any another institute, the faculty member are so helpful and very much knowledgeable in subject and the faculty member always help to understand the basic concept as well as the detailed concept . I am very happy to a part of the ABITE Institute.",
            author: "Harry",
            course: "Data Science",
            imageSrc: "img/testimonial-2.jpg"
        },
        {
            text: "Training was excellent with good interaction. Knowledge sharing is good. The recording facility is excellent for revising. The course was practically and informative. Faculties are enthusiastic and really aware of what they are explaining.I would highly recommend ABITE Institute for learning and enhancing IT skills.",
            author: "Amrita",
            course: "Web Design",
            imageSrc: "img/testimonial-1.jpg"
        }
    ];

    const steps = [
        { icon: <FaUserGraduate />, title: 'Step 1', subtitle: "Enroll for the course", descr: 'Enroll with confidence, your bright journey begins here' },
        { icon: <FaUserCog />, title: 'Step 2', subtitle: "Professional Training", descr: 'Get training in a modern way and learn from the expert' },
        { icon: <FaUsersGear />, title: 'Step 3', subtitle: "Hands-On Projects", descr: 'Learn as a Pro with our hand-on Projects' },
        { icon: <FaFileAlt />, title: 'Step 4', subtitle: "Resume & Placement", descr: 'We help you with resume-building and Placement also.' },

    ];

    const items = [
        { id: 2, iconClass: 'fa-location-dot', title: '100% Placement guarantee', description: 'ABITM , the best IT training institute in Kolkata, provides Complete Placement Assistance to its candidates.' },
        { id: 3, iconClass: 'fa-user-tie', title: 'Learn form 15 years experienced IT people', description: 'ABITM, the best software IT training institute in Kolkata, provides its students with proper training from industry experts' },

        { id: 1, iconClass: 'fa-cogs', title: 'On Job Training With Experience Certificate', description: 'Work on real-time projects with our trainers. Our professionals guide you to handle real-time projects. We prepare you for the market, train you to handle the live projects.' },
        { id: 4, iconClass: 'fa-user-graduate', title: 'Industry-Oriented Course Modules', description: 'We provide course material in PDF and video formats. The aim is to make you learn not just in our class but also at your home' },
        { id: 5, iconClass: 'fa-certificate', title: 'Globally Recognized Certificate', description: 'The certificate provided by us will be valid in any top company in the world as well as any recognized organizations.' },
        { id: 6, iconClass: 'fa-chalkboard-user', title: 'Online & Offline Classes', description: 'We provide both online and offline classes. You can choose as per your convenience. You can switch the mode at any time.' },
        { id: 7, iconClass: 'fa-id-card', title: 'Help In Resume & Interview', description: 'We help you in interview preparation and resume building. We provide you with mock interview questions and answers.' },
        { id: 8, iconClass: 'fa-users', title: 'Free Demo Classes', description: 'Join our free demo class to experience interactive lessons and hands-on practice. Get a preview of our expert-led curriculum designed to enhance your software skills.' }
        // Add more items as needed
    ];



    return (
        <>
            <div className="container-fluid pt-5 position-relative   hero-header overlay-bottom" style={{ marginBottom: "50px", height: "752px" }}>
                <div className="container pt-5 item">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            {/* <Link to="bhattacharjeegroup.com/" className="btn btn-outline-light rounded-pill  px-3 mb-3 animated slideInRight" >BG GROUP</Link> */}
                            <a
                                href="https://bhattacharjeegroup.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-outline-light rounded-pill px-3 mb-3 animated slideInRight"
                            >
                                BG GROUP
                            </a>
                            <h1 className=" text-white mb-4 animated slideInRight">AB Institute of Technology & Management</h1>

                            <p className="text-white mb-4 animated slideInRight">Education is the cornerstone of progress, and our institute is dedicated to nurturing the next generation of leaders and innovators. Through a comprehensive range of courses and programs, we provide students with the knowledge and skills they need to thrive in an ever-evolving world.</p>
                            <Link to='/all_courses' className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight">Explore All Courses</Link>

                        </div>
                        <div className="col-lg-6 align-self-end text-center text-lg-end">
                            <img className="img-fluids" src="../img/file3.webp" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid px-0 py-2  ">
                <div className="row mx-0 justify-content-center pt-5">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.2s">
                        <div className="section-titles  text-center position-relative mb-4">
                            <h6 className="d-inline-block position-relative text-danger section_subheading  text-uppercase pb-2">Our Courses</h6>
                            <h1 className="section_heading">Explore Our Best Courses</h1>
                        </div>
                    </div>
                </div>


                <OwlCarousel className="owl-carousel owl-theme courses-carousel wow fadeIn" data-wow-delay="0.5s" {...options}>
                    {courses.map((course, index) => (
                        <div className="courses-item position-relative" key={index}>
                            <img className="img-fluid" src={course.img} alt={course.title} />
                            <div className="courses-text">
                                <h4 className="text-center text-white course_title px-3">{course.title}</h4>
                                <div className="border-top w-100 mt-3">
                                    <div className="d-flex justify-content-between p-4">
                                        <span className="text-white">{course.instructor}</span>
                                        <span className="text-white">{course.rating} <small>{course.reviews}</small></span>
                                    </div>
                                </div>
                                <div className="w-100 bg-white text-center p-4">
                                    <a className="btn btn-primary" href={course.link}>Course Detail</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>





            <div className="container-fluid px-0 py-1">


                <div className="container-fluid bg-image " style={{ margin: "50px 0" }}>
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-lg-12  pt-5 pb-lg-5 wow fadeIn" data-wow-delay="0.5s">
                                <div className="section-titles position-relative  mb-4">
                                    <h6 className="d-inline-block text-danger position-relative text-secondary text-uppercase pb-2">Why Choose Us?</h6>
                                    <h1 className="">Why You Should Start Learning with Us?</h1>
                                </div>
                                <p className="mb-4 pb-2">With the changing trends in the IT industry, we always update our courses.
                                    ABITE Institute has been a premier digital marketing and web development course provider in Kolkata,
                                    with placement assistance.Whether you want to learn Data Science Course, MERN Stack Course, Graphic Design Course or any other,
                                    you will find us the best.</p>

                                <div className="choose_card_container">
                                    {items.map((item) => (
                                        <div key={item.id} className="choose_card">
                                            <div className="choose_card-inner">
                                                <div className="choose_card-front">
                                                    <div className="icon-title-container">
                                                        <div className="icon-container">
                                                            <i className={`fa fa-2x  ${item.iconClass}`} style={{ color: 'rgb(58, 110, 174)' }} ></i>
                                                        </div>
                                                        <div className="title-container mt-1">
                                                            <h4 style={{ color: 'rgb(58, 110, 174)' }}>{item.title}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="choose_card-back">
                                                    <div className="icon-title-container">
                                                        <div className="icon-container">
                                                            <i className={`fa fa-2x ${item.iconClass}`} style={{ color: 'rgb(58, 110, 174)' }}></i>
                                                        </div>
                                                        <div className="title-container mt-1">
                                                            <h6>{item.description}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-primary bg-icon my-5 py-2">
                    <div className="container ">
                        <div className="row g-5">
                            <div className="section-titles  text-center position-relative">
                                <h5 className="d-inline-block position-relative   text-uppercase pb-2" style={{ color: "white" }}>How We Works</h5>
                                <h1 className=" text-white">What is process ABITM? </h1>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-12 text-center wow fadeInUp" data-wow-delay="0.5s">
                        <div className="process">
                            <ol>
                                {steps.map((step, index) => (
                                    <li key={index}>
                                        <div className="icon"> {step.icon}</div>
                                        <div className="title">{step.title}</div>
                                        <div className="descr">{step.descr}</div>
                                        <h4 className="bottom-heading">{step.subtitle}</h4>
                                    </li>
                                ))}
                            </ol>

                        </div>
                    </div>

                </div>

                <div class="container-xxl py-3 px-0 wow zoomIn" data-wow-delay="0.1s">
                    <div class="row g-0">
                        <div class="col-md-6 bg-dark d-flex align-items-center">
                            <div class="p-5">
                                <h1 class="section-title-hotel text-start text-white text-uppercase mb-3">Best Learning</h1>
                                <h4 class="text-white mb-4">Learn Our  Enabled Couses
                                    ABITE Institute has included
                                    AI in all our courses to make you the
                                    pioneer in the IT Industry</h4>
                                <p class="text-white mb-4">Welcome to Bhattacharjee Group's Institute, where innovation fuels education for the future of software. Our dynamic programs empower with cutting-edge skills in web development, AI, and machine learning, ensuring industry readiness. Led by experts, join us to unleash your potential.</p>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="video-institute ">
                                <button type="button" class="btn-play" data-bs-toggle="modal" data-src="https://youtu.be/Kz3W_H3H92A?si=sShK0YJIb5Eg0njZ" data-bs-target="#videoModal">
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content rounded-0">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">ABITE Institute</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <div class="ratio ratio-16x9">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Kz3W_H3H92A?si=sShK0YJIb5Eg0njZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>




        </>

    )
}