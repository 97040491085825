
import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Institution from './institute/institute';
import All_courses from './institute/All_courses';
import Header from './navbar/Header';
import Footer from './navbar/Footer';
import About from './navbar/About_us';
import Contact from './navbar/Contact';
import Placement from './navbar/Placement';
import Course_details from './institute/Course_details';
import Ai_courses from './institute/Ai_courses';
import Full_stack_courses from './institute/Full_stack_courses';
import Digital_marketing_courses from './institute/Digital_marketing_courses';
import UI_UX_design_courses from './institute/UX_design_courses';
// import courseData from './institute/All_courses';

function App() {
   
  const courseData = [
    {
        id: 1,
        image: "/img/Institute/ui.png",
        title: "WEB DESIGN",
        description: 'HTML, CSS, Bootstrap, Mobile & Browser Compatibility.',
        description2: 'This comprehensive course covers essential aspects of web design, including HTML, CSS, Bootstrap for responsive design, and ensuring compatibility across various mobile devices and browsers. Students will learn to create visually appealing and functional websites.',
        price: "15000",
        duration: '3 months',
        insideHeading: "WEB DESIGN",
        videoSrc: "img/Institute/ui.png",
        course_img:"../img/Institute/web_design 2.jpg"
    },
    {
        id: 2,
        image: "img/Institute/full stack.jpg",
        title: "FULL STACK COURSES",
        description: 'Build expertise in full-stack development from frontend to backend.',
        description2: 'This course provides a deep dive into full-stack development, teaching students to build applications from scratch using both front-end and back-end technologies. Topics include HTML, CSS, JavaScript, database management, server-side development, and more.',
        price: "15000",
        duration: '4 months',
        insideHeading: "FULL STACK COURSES",
        videoSrc: "img/Institute/full stack.jpg",
        course_img:"../img/Institute/fullstack.avif"

    },
    {
        id: 3,
        image: "img/Institute/mern.jpg",
        title: "MERN STACK COURSES",
        description: "MongoDB, Express, ReactJS, Node.js.",
        description2: 'In this course, students will master the MERN stack, a popular set of technologies for building modern web applications. The curriculum includes MongoDB for database management, Express.js for server-side development, React.js for front-end development, and Node.js for server-side scripting.',
        price: "18000",
        duration: '5-6 months',
        insideHeading: "MERN STACK COURSES",
        videoSrc: "img/Institute/mern.jpg",
        course_img:"../img/Institute/mern.png"
        
    },
    {
        id: 4,
        image: "img/Institute/mean.jpg",
        title: "MEAN STACK COURSES",
        description: "MongoDB, Express, AngularJS, Node.js.",
        description2: 'This course covers the MEAN stack, a powerful combination of technologies for building dynamic web applications. Students will learn MongoDB for database management, Express.js for server-side development, AngularJS for front-end development, and Node.js for server-side scripting.',
        price: "18000",
        duration: '5-6 months',
        insideHeading: "MEAN STACK COURSES",
        videoSrc: "img/Institute/mean.jpg",
        course_img:"../img/Institute/mern.png"
    },
    {
        id: 5,
        image: "img/Institute/ai.png",
        title: "AI COURSES",
        description: "An introduction to artificial intelligence principles and applications.",
        description2: 'This introductory course provides a solid foundation in artificial intelligence, covering key concepts, principles, and applications. Students will explore machine learning, neural networks, and various AI algorithms, gaining hands-on experience through practical projects.',
        price: "18000",
        duration: '5-6 months',
        insideHeading: "AI COURSES",
        videoSrc: "img/Institute/ai.png",
        course_img:"../img/Institute/ai.avif"

    },
    {
        id: 6,
        image: "img/Institute/digital marketing.png",
        title: "DIGITAL MARKETING COURSES",
        description: "SEO, Email Marketing, Google Adwords, Facebook Ad's",
        description2: 'This course offers comprehensive training in digital marketing strategies and tools. Topics include search engine optimization (SEO), email marketing, Google AdWords, and Facebook Ads. Students will learn to create effective digital marketing campaigns to drive traffic and engagement.',
        price: "18000",
        duration: '5-6 months',
        insideHeading: "DIGITAL MARKETING ",
        videoSrc: "img/Institute/digital marketing.png",
         course_img:"../img/Institute/digital_marketing.jpg"
    },

    {
        id: 7,
        image: "img/Institute/web dev.png",
        title: "WEB DEVELOPMENT COURSES",
        description: "Discover the essentials of web development: from HTML and CSS to JavaScript and beyond.",
        description2: 'This course covers the essentials of web development, starting with HTML and CSS for building the structure and style of web pages, and moving on to JavaScript for adding interactivity. Students will also explore advanced topics and frameworks for modern web development.',
        price: "18000",
        duration: '5-6 months',
        insideHeading: "WEB DEVELOPMENT ",
        videoSrc: "img/Institute/web dev.png",
        course_img:"../img/Institute/webb.jpg"
  
    },
    {
        id: 8,
        image: "img/Institute/python full stack.jpg",
        title: "PYTHON FULL STACK",
        description: "Python & Django Framework Course for candidates willing to embark on full-stack development",
        description2: 'This full-stack development course focuses on Python and the Django framework. Students will learn to build complete web applications, from the backend using Python and Django to the frontend using HTML, CSS, and JavaScript. The course includes database management and deployment.',
        price: "18000",
        duration: '5-6 months',
        insideHeading: "PYTHON FULL STACK",
        videoSrc: "img/Institute/python full stack.jpg",
        course_img:"../img/Institute/Python-Java1.png"
    },
    {
        id: 9,
        image: "img/Institute/python programing.jpg",
        title: "PYTHON PROGRAMING ",
        description: "Master Python programming fundamentals for versatile application development.",
        description2: 'This course covers the fundamentals of Python programming, providing a strong foundation for versatile application development. Students will learn about variables, data types, control structures, functions, and modules, as well as how to write efficient and readable code.',
        price: "10000",
        duration: '1 months',
        insideHeading: "PYTHON PROGRAMING",
        videoSrc: "img/Institute/python programing.jpg",
        course_img:"../img/Institute/P.avif"

    },
    {
        id: 10,
        image: "img/Institute/python.jpg",
        title: "PYTHON COURSES",
        description: "Python & Django Framework Course ",
        description2: 'This course offers a comprehensive introduction to Python and the Django framework. Students will learn to build web applications using Python for backend development and Django for rapid development and clean, pragmatic design. The course includes hands-on projects and real-world applications.',
        price: "8000",
        duration: '1 months',
        insideHeading: "PYTHON COURSES",
        videoSrc: "img/Institute/python.jpg",
        course_img:"../img/Institute/Pp.jpg"

    },
    {
        id: 11,
        image: "img/Institute/master of data analytics.jpg",
        title: "MASTER OF DATA ANALYTICS",
        description: "Advanced skills in data analysis, statistical modeling, and data-driven decision-making. ",
        description2: 'This advanced course in data analytics covers key topics such as statistical modeling, data visualization, and data-driven decision-making. Students will learn to use various tools and techniques to analyze and interpret data, providing insights that drive business strategies.',
        price: "15000",
        duration: '3 months',
        insideHeading: "MASTER OF DATA ANALYTICS",
        videoSrc: "img/Institute/master of data analytics.jpg",
        course_img:"../img/Institute/data.jpg"

    },
    {
        id: 12,
        image: "img/Institute/data analytics.jpg",
        title: "DATA ANALYTICS COURSES",
        description: "Comprehensive introduction to data science using R programming. ",
        description2: 'This course provides a comprehensive introduction to data analytics, focusing on R programming. Students will learn to manipulate and analyze data, create visualizations, and apply statistical methods. The course includes practical projects to develop real-world data analysis skills.',
        price: "15000",
        duration: '3 months',
        insideHeading: "DATA ANALYTICS COURSES",
        videoSrc: "img/Institute/data analytics.jpg",
        course_img:"../img/Institute/data.jpg"

    },
    {
        id: 13,
        image: "img/Institute/ml.jpg",
        title: "MACHINE LEARNING",
        description: "Learn the basics of machine learning, from data processing to model evaluation. ",
        description2: 'This introductory course in machine learning covers essential topics such as data preprocessing, model selection, training, and evaluation. Students will learn various machine learning algorithms and techniques, gaining hands-on experience through practical exercises and projects.',
        price: "18000",
        duration: '3 months',
        insideHeading: "MACHINE LEARNING",
        videoSrc: "img/Institute/ml.jpg",
        course_img:"../img/Institute/machine-learning.png"

    },
    {
        id: 14,
        image: "img/Institute/ml ai.jpg",
        title: "ML/AI COURSES",
        description: "Explore essential ML/AI concepts, algorithms, and applications. ",
        description2: 'This course explores essential concepts in machine learning and artificial intelligence, covering a range of algorithms and applications. Students will learn to develop and implement ML/AI models, understanding their practical applications in various industries.',
        price: "20000",
        duration: '3 months',
        insideHeading: "ML/AI COURSES",
        videoSrc: "img/Institute/ml ai.jpg",
        course_img:"../img/Institute/mlai.webp"

    },
    {
        id: 15,
        image: "img/Institute/php.jpg",
        title: "PHP FULL STACK",
        description: "Master PHP and full-stack web development, from frontend to backend.",
        description2: 'This full-stack development course focuses on PHP, covering both frontend and backend development. Students will learn to build dynamic web applications using PHP, HTML, CSS, JavaScript, and various databases. The course includes hands-on projects to develop practical skills.',
        price: "18000",
        duration: '3 months',
        insideHeading: "PHP FULL STACK",
        videoSrc: "img/Institute/php.jpg",
        course_img:"../img/Institute/php.avif"

    },
    {
        id: 16,
        image: "img/Institute/react js.jpg",
        title: "REACT JS",
        description: "HTML5, CSS3, ES6, Component, Redux, Node API. ",
        description2: 'This course provides a thorough introduction to React JS, covering HTML5, CSS3, ES6, and advanced topics like Redux and Node API. Students will learn to build dynamic, component-based web applications using React, gaining hands-on experience through practical projects.',
        price: "8000",
        duration: '1 months',
        insideHeading: "REACT JS",
        videoSrc: "img/Institute/react js.jpg",
        course_img:"../img/Institute/react.avif"

    },
    {
        id: 17,
        image: "img/Institute/node js.jpg",
        title: "NODE JS",
        description: "Development Environment, REPL, NPM, DB connectivity, Express. ",
        description2: 'This course covers the fundamentals of Node.js, including setting up a development environment, using REPL, managing packages with NPM, and connecting to databases. Students will learn to build server-side applications using Node.js and Express, gaining practical experience through hands-on projects.',
        price: "8000",
        duration: '1 months',
        insideHeading: "NODE JS",
        videoSrc: "img/Institute/node js.jpg",
        course_img:"../img/Institute/nodejs.svg"

    },
    {
        id: 18,
        image: "img/Institute/frontend.jpg",
        title: "FRONTEND COURSES",
        description: "HTML5, CSS3, ES6,React js,Angular js,Next js. ",
        description2: 'This course provides a comprehensive introduction to frontend development, covering HTML5, CSS3, ES6, and popular frameworks like React.js, Angular.js, and Next.js. Students will learn to build responsive and interactive web applications, gaining hands-on experience through practical projects.',
        price: "8000",
        duration: '1 months',
        insideHeading: "FRONTEND COURSES",
        videoSrc: "img/Institute/frontend.jpg",
        course_img:"../img/Institute/front.jpg"

    },
    {
        id: 19,
        image: "img/Institute/next js.jpg",
        title: "NEXT JS",
        description: "Learn to build fast, modern web apps with Next.js. ",
        description2: 'This course focuses on Next.js, a powerful framework for building fast, modern web applications. Students will learn to create server-rendered React applications, optimize performance, and manage routing and data fetching. The course includes hands-on projects to develop practical skills.',
        price: "8000",
        duration: '1 months',
        insideHeading: "NEXT JS",
        videoSrc: "img/Institute/next js.jpg",
        course_img:"../img/Institute/nextjs.svg"

    },
    {
        id: 20,
        image: "img/Institute/angular.jpg",
        title: "ANGULAR",
        description: "HTML5, CSS3, SCSS, TypeScript , ES6, Routing. ",
        description2: 'This course provides a comprehensive introduction to Angular, covering HTML5, CSS3, SCSS, TypeScript, ES6, and routing. Students will learn to build dynamic, single-page applications using Angular, gaining hands-on experience through practical projects.',
        price: "8000",
        duration: '1 months',
        insideHeading: "ANGULAR",
        videoSrc: "img/Institute/angular.jpg",
        course_img:"../img/Institute/angular1.jpg"

    },
    {
        id: 21,
        image: "img/Institute/basic.jpg",
        title: "C, C++, CCC ",
        description: "C programming, Basic Network and Basic Electrical & Electronics. ",
        description2: 'This course covers the fundamentals of C and C++ programming, along with basic networking and electrical & electronics concepts. Students will learn to write efficient code in C and C++, understand basic networking principles, and gain knowledge of electrical and electronic systems.',
        price: "8000",
        duration: '1 months',
        insideHeading: "C, C++ ,CCC",
        videoSrc: "img/Institute/basic.jpg",
        course_img:"../img/Institute/cc.jpg"

    },
    {
        id: 22,
        image: "img/Institute/basicss.jpg",
        title: "BASICS COMPUTER COURSES",
        description: "Learn the fundamentals of computer basics and essential skills. ",
        description2: 'This course provides a comprehensive introduction to basic computer skills, covering essential topics such as operating systems, word processing, spreadsheets, and internet usage. Students will gain practical skills and knowledge to use computers effectively in various contexts.',
        price: "5000",
        duration: '1 months',
        insideHeading: "BASICS COMPUTER COURSES",
        videoSrc: "img/Institute/basicss.jpg",
        course_img:"../img/Institute/basics.jpg"

    },
    {
        id: 23,
        image: "img/Institute/prompt.jpg",
        title: "PROMPT ENGINEERING ",
        description: "Learn the principles and practices of prompt engineering for effective natural language processing tasks. ",
        description2: 'This course focuses on prompt engineering, teaching students the principles and practices for effective natural language processing (NLP) tasks. Students will learn to design and implement prompts for various NLP applications, gaining practical experience through hands-on projects.',
        price: "10000",
        duration: '1 months',
        insideHeading: "PROMPT ENGINEERING",
        videoSrc: "img/Institute/prompt.jpg",
        course_img:"../img/Institute/promptt.jpg"

    },
    {
        id: 24,
        image: "img/Institute/seo.jpg",
        title: "SEO",
        description: "Discover effective SEO strategies and techniques in this practical course. ",
        description2: 'This course provides a comprehensive introduction to search engine optimization (SEO), covering effective strategies and techniques to improve website visibility and ranking. Students will learn to conduct keyword research, optimize content, and analyze performance, gaining practical experience through real-world projects.',
        price: "8000",
        duration: '1 months',
        insideHeading: "SEO",
        videoSrc: "img/Institute/seo.jpg",
        course_img:"../img/Institute/seooo.jpg"

    }
];


  return (
    
<>
<Router >
  <Header/>
  <Routes>
    
    <Route path='/' element={<Institution/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/placement' element={<Placement/>}/>
    <Route path='/all_courses' element={<All_courses/>}/>
    <Route path='/course_details/:id' element={<Course_details courseData1={courseData}/>}/>
    <Route path='/ai_courses' element={<Ai_courses/>}/>
    <Route path='/full_stack_courses' element={<Full_stack_courses/>}/>
    <Route path='/ui_ux_design' element={<UI_UX_design_courses/>}/>
    <Route path='/digital_marketing' element={<Digital_marketing_courses/>}/>

  </Routes>
  <Footer/>
</Router>

</>
  );
}

export default App;
