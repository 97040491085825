import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default function UI_UX_design_courses() {

 
    useEffect(() => {
        scrollToTop();
    }, []);

        
    const courseData = [

        { id: 1,
            image: "img/Institute/ui.png",
            title: "WEB DESIGN",
            description: 'HTML, CSS, Bootstrap, Mobile & Browser Compatibility.',
            price: "15000",
            duration:'3 months',
            insideHeading: "WEB DESIGN",
            videoSrc: "img/Institute/ui.png",
            course_img:"../img/Institute/web_design.jpg"
        },
       
        { id: 7,
            image: "img/Institute/web dev.png",
            title: "WEB DEVELOPMENT COURSES",
            description: "Discover the essentials of web development: from HTML and CSS to JavaScript and beyond.",
            price: "18000",
            duration:'5-6 months',
            insideHeading: "WEB DEVELOPMENT ",
            videoSrc: "img/Institute/web dev.png"
        },

   

        { id: 10,
            image: "img/Institute/python.jpg",
            title: "PYTHON COURSES",
            description: "Python & Django Framework Course ",
            price: "8000",
            duration:'1 months',
            insideHeading: "PYTHON COURSES",
            videoSrc: "img/Institute/python.jpg"
        },
       
        { id: 16,
            image: "img/Institute/react js.jpg",
            title: "REACT JS",
            description: "HTML5, CSS3, ES6, Component, Redux, Node API. ",
            price: "8000",
            duration:'1 months',
            insideHeading: "REACT JS",
            videoSrc: "img/Institute/react js.jpg"
        },
        {  id: 17,
            image: "img/Institute/node js.jpg",
            title: "NODE JS",
            description: "Development Environment, REPL, NPM, DB connectivity, Express. ",
            price: "8000",
            duration:'1 months',
            insideHeading: "NODE JS",
            videoSrc: "img/Institute/node js.jpg"
        },

        {  id: 18,
            image: "img/Institute/frontend.jpg",
            title: "FRONTEND COURSES",
            description: "HTML5, CSS3, ES6,React js,Angular js,Next js. ",
            price: "8000",
            duration:'1 months',
            insideHeading: "FRONTEND COURSES",
            videoSrc: "img/Institute/frontend.jpg"
        },
        {  id: 19,
            image: "img/Institute/next js.jpg",
            title: "NEXT JS",
            description: "Learn to build fast, modern web apps with Next.js. ",
            price: "8000",
            duration:'1 months',
            insideHeading: "NEXT JS",
            videoSrc: "img/Institute/next js.jpg"
        },
        {  id: 20,
            image: "img/Institute/angular.jpg",
            title: "ANGULAR",
            description: "HTML5, CSS3, SCSS, TypeScript , ES6, Routing. ",
            price: "8000",
            duration:'1 months',
            insideHeading: "ANGULAR",
            videoSrc: "img/Institute/angular.jpg"
        },


    ];

    return (
        <>
            <div className="bg-primary hero-header">
                <div className="jumbotron page-header jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "20px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <h1 className="text-white mt-1 mb-3 animated slideInRight">Learn From ABITM Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">UI/UX Design Courses</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card-area">
                {courseData.map((card, index) => (
                    <div key={index} className="card">
                        <div className="flip-card">
                            <div className="flip-card__container">
                                <div className="card-front">
                                    <div className='card-front__tp'>
                                        <img src={card.image} alt={card.insideHeading} className="card-front__image" />
                                    </div>
                                    <div className="card-front__bt">
                                        <p className='card-front__text-view'>{card.title}</p>
                                    </div>
                                </div>
                                <div className="card-back">
                                    <img className="" src={card.videoSrc} alt={`${card.title} video`} />
                                </div>
                            </div>
                        </div>
                        <div className="inside-page">
                            <div className="inside-page__container">
                                <h3 className='inside-page__heading'>{card.insideHeading}</h3>
                                <p className="inside-page__text">
                                    {card.description}
                                    <br />
                                    <strong>Price:</strong> ₹{card.price}
                                    <br />
                                    <strong>Duration:</strong> {card.duration}
                                </p>
                                <Link to={`/course_details/${card.id}`} className="inside-page__btn inside-page__btn--camping">Learn More</Link>

                            </div>


                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

