import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom";

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

  


export default function Course_details({ courseData1 }) {

    useEffect(() => {
        scrollToTop();
    }, []);

    const [activeIndex, setActiveIndex] = useState(null);
    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const { id } = useParams();
    
    if (!Array.isArray(courseData1)) {
        return <div>Course data is not available</div>;
    }

    const course = courseData1.find(course => course.id === parseInt(id));

    if (!course) {
        return <div>Course not found</div>;
    }

    const { title, description,description2, price, duration, course_img } = course;


    return (
        <>
      
            <div className=" bg-primary hero-header ">
                <div className="jumbotron page-header jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "50px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">

                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>

                                <h1 className=" text-white mt-1  mb-3 animated slideInRight"> ABITE Institute</h1>
                                <h1 className=" text-white mb-5 animated slideInRight">{title}</h1>
                                {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Explore All Courses </div> */}

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        

            <div class="container-fluid py-2">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="mb-5">
                                <div class="section-titles position-relative mb-2 ">
                                    <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">Course Detail</h6>
                                    <h1 class="">{title}</h1>
                                </div>
                                <img class="img-fluid rounded w-100 mb-2" src={course_img}style={{maxWidth: "70%" }} alt="Image" />
                                <p>{description2}</p>
                                <p>{description}</p>
                            </div>

                   
                        </div>

                        <div class="col-lg-4 mt-3 mt-lg-0 ">
                            <div class="bg-primary mb-5 py-3  " >
                                <h4 class="text-white py-3 px-4 m-0">Course Features</h4>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Course Duration</h6>
                                    <h6 class="text-white my-3">{duration}</h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Class</h6>
                                    <h6 class="text-white my-3">3 days</h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Mode Of Training</h6>
                                    <h6 class="text-white my-3">Classroom,Online,WeekEnd</h6>

                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Duration</h6>
                                    <h6 class="text-white my-3">3.00 Hrs</h6>
                                </div>
                                <div class="d-flex justify-content-between border-bottom px-4">
                                    <h6 class="text-white my-3">Skill level</h6>
                                    <h6 class="text-white my-3">All Level</h6>
                                </div>
                                <div class="d-flex justify-content-between px-4">
                                    <h6 class="text-white my-3">Language</h6>
                                    <h6 class="text-white my-3">Hindi,English,Bengali</h6>
                                </div>
                                <h4 class="text-white py-3 px-4 m-0">Course Price: {price}</h4>
                                {/* <div class="py-3 px-4">
                            <a class="btn btn-block btn-secondary py-3 px-5" href="">Enroll Now</a>
                        </div> */}
                            </div>

                            <div class="mb-5">
                                <h2 class="mb-3">Course Content</h2>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a  class="text-decoration-none h6 m-0">HTML 5</a>
                                        {/* <span class="badge badge-primary badge-pill">150</span> */}
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a  class="text-decoration-none h6 m-0">CSS 3</a>
                                        {/* <span class="badge badge-primary badge-pill">131</span> */}
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a  class="text-decoration-none h6 m-0">JavaScript</a>
                                        {/* <span class="badge badge-primary badge-pill">78</span> */}
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a  class="text-decoration-none h6 m-0">React Js</a>
                                        {/* <span class="badge badge-primary badge-pill">56</span> */}
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a  class="text-decoration-none h6 m-0">Node Js</a>
                                        {/* <span class="badge badge-primary badge-pill">98</span> */}
                                    </li>

                                    <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                        <a  class="text-decoration-none h6 m-0">MOngo DB</a>
                                        {/* <span class="badge badge-primary badge-pill">98</span> */}
                                    </li>
                                </ul>
                            </div>

             
                        </div>
                    </div>

                    <div className="row">
                        <div className="col ">
                            <h6 className="mt-2 mb-4 course_content ">Course Content</h6>
                            <div className="accordion mt-4" id="accordionExample">

                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className={`accordion-button    ${activeIndex === 0 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(0)}
                                            aria-expanded={activeIndex === 0}
                                            aria-controls="collapseOne"
                                        >
                                            HTML 5
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className={`accordion-collapse collapse ${activeIndex === 0 ? 'show' : ''}`}
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="p-4">
                                            <ul className="d-grid">
                                                <li className="float-start me-3"><FaCheck className="me-2" /> What is HTML5?</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" /> HTML5 Basic Syntex</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" /> H1,H2, and other tags</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" /> Normal tags and semantic tags</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" /> hyperlinks</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" /> table</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" />HTML5 form</li>
                                                <li className="float-start me-3"><FaCheck className="me-2" />HTML 5 form validation</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className={`accordion-button  ${activeIndex === 1 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(1)}
                                            aria-expanded={activeIndex === 1}
                                            aria-controls="collapseTwo"
                                        >
                                            CSS 3
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className={`accordion-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="p-4">
                                            <ul className="d-grid">
                                                <li className="mb-2"><FaCheck className="me-2" /> What is CSS?</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Basic syntax of CSS</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> H1, H2, and other tags</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Font, Color, and Size</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Div/CSS</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Create basic layout with CSS</li>


                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className={`accordion-button bg-gray ${activeIndex === 2 ? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(2)}
                                            aria-expanded={activeIndex === 2}
                                            aria-controls="collapseThree"
                                        >
                                            JavaScript
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className={`accordion-collapse collapse ${activeIndex === 2 ? 'show' : ''}`}
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="p-4">
                                            <ul className="d-grid">
                                                <li className="mb-2"><FaCheck className="me-2" /> Introduction to Vanilla JavaScript</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Variables and Data Types</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Control Flow and Loops</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Functions and Scope</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Arrays and Objects</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> DOM Manipulation</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Events and Event Handling</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Asynchronous JavaScript (Promises, Async/Await)</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> LocalStorage and SessionStorage</li>





                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className={`accordion-button bg-gray ${activeIndex === 3? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(3)}
                                            aria-expanded={activeIndex === 3}
                                            aria-controls="collapseFour"
                                        >
                                           React Js
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className={`accordion-collapse collapse ${activeIndex === 3 ? 'show' : ''}`}
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="p-4">
                                            <ul className="d-grid">
                                                <li className="mb-2"><FaCheck className="me-2" /> Introduction to React</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Setting Up a React App</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> JSX and Components</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> State and Props</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Lifecycle Methods</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Handling Events in React</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Conditional Rendering</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Lists and Keys</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Forms in React</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> React Router for Navigation</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> State Management with Context API</li>
                                                <li className="mb-2"><FaCheck className="me-2" />Hooks (useState, useEffect, etc.)</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Redux State Management</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Redux Toolkit</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Fetching Data in React</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Error Boundaries</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Testing in React</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Styling in React (CSS-in-JS)</li>





                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className={`accordion-button bg-gray ${activeIndex === 4? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(4)}
                                            aria-expanded={activeIndex === 4}
                                            aria-controls="collapseFive"
                                        >
                                           Node Js
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className={`accordion-collapse collapse ${activeIndex === 4 ? 'show' : ''}`}
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="p-4">
                                            <ul className="d-grid">
                                                <li className="mb-2"><FaCheck className="me-2" /> Introduction to Node.js</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Setting Up a Node.js Project</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Node.js Modules and NPM</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Express.js Framework</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Creating RESTful APIs with Express</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Handling HTTP Requests and Responses</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Middleware in Express</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Connecting to MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Creating MongoDB Schemas</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> CRUD Operations with MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Express Routes and Controllers</li>
                                                <li className="mb-2"><FaCheck className="me-2" />Authentication in Node.js</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Error Handling in Node.js</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Middleware for Authorization</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Testing in Node.js</li>
                                                

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className={`accordion-button bg-gray ${activeIndex === 5? '' : 'collapsed'}`}
                                            type="button"
                                            onClick={() => handleToggle(5)}
                                            aria-expanded={activeIndex === 5}
                                            aria-controls="collapseSix"
                                        >
                                          MOngo DB
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        className={`accordion-collapse collapse ${activeIndex === 5 ? 'show' : ''}`}
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="p-4">
                                            <ul className="d-grid">
                                                <li className="mb-2"><FaCheck className="me-2" /> Introduction to MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Installation and Setup</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> MongoDB Data Modeling</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> CRUD Operations in MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Querying Data in MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Indexes and Performance</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Aggregation Framework</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Transactions in MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Data Validation and Schema Design</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Working with Geospatial Data</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> MongoDB Atlas and Cloud Deployment</li>
                                                <li className="mb-2"><FaCheck className="me-2" />Security Best Practices</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Backup and Restore in MongoDB</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> MongoDB Compass (GUI for MongoDB)</li>
                                                <li className="mb-2"><FaCheck className="me-2" /> Scaling MongoDB</li>
                                                

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                          
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}